import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/12.png"
import MarekK from "../images/photos/marekKobulsky.jpg"

const LECTURER = [
  {
    src: MarekK,
    alt: "",
    personName: "Marek Kobulský",
    text: [
      "Marek se pohybuje v online marketingu od roku 2014. Má zkušenosti jako Data Scientist, CMO a CTO.",
      "Pracoval jak na straně klienta, tak pro agenturu, ale i jako freelance. Díky tomu dokáže hezky kombinovat byznysové požadavky s analytickou utopií. Umí být na všechno sám, ale i vést 10členný tým. Přednášel v Londýně, Varšavě, Kyjevě, nebo na Havaji.",
      "Od 4 let hraje na piáno a soutěží na mezinárodní úrovni. Spoluzaložil projekt MarketingIntelligence.io, ve kterém řeší oblasti zákaznické a produktové analytiky, atribučního modelování, nebo Media Mix Modeling pro klienty u nás  i v zahraničí.",
    ],
    linkedInProfile: "//linkedin.com/in/marek-kobulsk%C3%BD-0349229a/",
  },
]

const MarketingMixModelling: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper title="Marketing mix modelling" ogImage={OgImage} />

      <SyllabusHeader
        orangeText="Marketing mix modelling"
        numberOfLesson="12/13"
      ></SyllabusHeader>

      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>
          nastolení problému
          <div>
            měření přínosu kanálů a kampaní v multi-touch online-offline
            neměřitelném ekosystému se značným odpadem vstupních dat
          </div>
        </div>
        <div>
          historie, ekonometrie, dekompozice časové řady, tradiční chápání a
          úskalí statistických metod
        </div>

        <div>
          úsvit moderního MMM modelování, Google, Meta, Tiktok, Uber, …
          <div>
            open-source knihovny, co je potřeba pro vlastní výpočet a má vůbec
            smysl něco vyvíjet
          </div>
          <div>
            on-premise vs. cloud výpočty, rozdíly, ceny, kdy se co vyplatí
          </div>
        </div>

        <div>
          příprava modelu
          <div>struktura a logika vstupního datasetu</div>
          <div>jak uvažovat nad channelgroupingem, konkrétní příklady</div>
          <div>co (ne)patří do baseline, opět příklady od reálných klientů</div>
          <div>jak se navigovat mezi modely, red flags – co nedává smysl</div>
        </div>

        <div>
          jak v co nejmenším počtu kroků dokonvergovat k rozumnému řešení
        </div>

        <div>
          jak funguje budget allocator, omezování kanálů, nastavování hranic a
          parametrů
        </div>

        <div>
          co jsou saturační křivky, jak vypadají a proč vypadajú pro různé
          kanály jinak
        </div>

        <div>
          klikání v MMM appce
          <div>
            společný průchod demo výpočtem a zvyšování kvality výsledního modelu
          </div>
          <div>MMM na vlastních datech (napojení alespoň do GAds + FB)</div>
        </div>

        <div>kontrolované experimenty, kalibrace MMM, příklady a výsledky</div>

        <div>
          návod, jak postupnými změnami prokazatelně docílit celkové zvýšení
          efektivity (ROI)
        </div>

        <div>case studies a success stories</div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default MarketingMixModelling
